import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0fc0058e"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=0fc0058e&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SwiperItem: require('D:/Git-Alwu/peihong/winbeta-new/peihong/components/swiper/swiper-item.vue').default,Swiper: require('D:/Git-Alwu/peihong/winbeta-new/peihong/components/swiper/swiper.vue').default,CircleProgress: require('D:/Git-Alwu/peihong/winbeta-new/peihong/components/circleProgress/circleProgress.vue').default,Mouse: require('D:/Git-Alwu/peihong/winbeta-new/peihong/components/mouse/mouse.vue').default,CanvasLogo: require('D:/Git-Alwu/peihong/winbeta-new/peihong/components/canvasLogo/canvasLogo.vue').default,LoadingIP: require('D:/Git-Alwu/peihong/winbeta-new/peihong/components/LoadingIP/LoadingIP.vue').default})
