
import { throttle, debounce } from "./utils.js";
import AlloyFinger from "./alloyFinger";
export default {
  name: "Slider",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    width: {
      type: String,
      default: "auto",
    },
    height: {
      type: String,
      default: "100%",
    },
    touch: {
      type: Boolean,
      default: true,
    },
    itemWidth:{
      type: String,
      default: "100%",
    },
    animation: {
      type: String,
      default: "normal",
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    stopOnHover: {
      type: Boolean,
      default: false,
    },
    interval: {
      type: Number,
      default: 3000,
    },
    speed: {
      type: Number,
      default: 500,
    },
    indicators: {
      type: [String, Boolean],
      default: "center",
    },
    controlBtn: {
      type: Boolean,
      default: true,
    },
    beforePrevious: {
      type: Function,
      default: () => true,
    },
    beforeNext: {
      type: Function,
      default: () => true,
    },
    prevBtnLabel: {
      type: String,
      default: "Previous slide",
    },
    nextBtnLabel: {
      type: String,
      default: "Next slide",
    },
  },
  data() {
    return {
      sliderItems: [],
      currentIndex: 0,
      timer: 0,
      af: null,
      isStopped: false,
    };
  },
  watch: {
    value(current) {
      const step = current - this.currentIndex;
      if (!step || current < 0 || current > this.sliderItems.length - 1) return;
      this.handleIndicator(step);
    },
  },
  created() {
    this.init = throttle(this.init, 100);
    this.move = debounce(this.move, this.speed - 200);
    this.$on("slider:init", this.init);
  },
  mounted() {
    this.init();
    this.initTouchArea();
  },
  // init when keep-alive
  activated() {
    this.init();
    this.initTouchArea();
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
    this.af && this.af.destroy();
  },
  deactivated() {
    this.timer && clearInterval(this.timer);
    this.af && this.af.destroy();
    this.af = null;
  },
  methods: {
    init() {
      this.sliderItems = this.$children.filter((child) => {
        return child.$options.name === "SliderItem";
      });
      if (this.sliderItems[this.value]) {
        this.currentIndex = this.value;
      }
      const currentItem = this.sliderItems[this.currentIndex];
      if (!currentItem) return;
      currentItem.init();
      this.auto();
    },
    initTouchArea() {
      if (this.af || !this.touch) return;
      const touchArea = this.$refs.touchArea;
      this.af = new AlloyFinger(touchArea, {
        swipe: (e) => {
          e.direction === "Left" ? this.next() : this.prev();
        },
      });
    },
    auto() {
      if (!this.autoplay || this.isStopped) return;
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.move(1);
      }, this.interval);
    },
    move(step) {
      if (!step || !this.canMove()) return;
      // direction: left: true, right: false
      const direction = step > 0;
      const nextIndex = this.getNextIndex(step);
      const currentItem = this.sliderItems[this.currentIndex];
      const nextItem = this.sliderItems[nextIndex];
      currentItem.hide(direction);
      nextItem.show(direction);
      this.currentIndex = nextIndex;
      this.$emit("change", nextIndex);
    },
    // 根据传递的索引切换轮播
    setIndex(step){
      const direction = step > 0;
      let nextIndex = step
      if(step >= this.sliderItems.length - 1) {
        nextIndex = this.sliderItems.length - 1
      }
      const currentItem = this.sliderItems[this.currentIndex];
      const nextItem = this.sliderItems[nextIndex];
      currentItem.hide(direction)
      nextItem.show(direction)
      this.currentIndex = nextIndex
    },
    prev() {
      if (!this.beforePrevious()) return;
      this.handleControlBtn("previous");
    },
    next() {
      if (!this.beforeNext()) return;
      this.handleControlBtn("next");
    },
    handleIndicator(step) {
      if (!step || !this.canMove()) return;
      this.move(step);
      this.auto();
    },
    /**
     * @param direction 'previous' | 'next'
     */
    handleControlBtn(direction) {
      if (!this.canMove()) return;
      const step = direction === "next" ? 1 : -1;
      const nextIndex = this.getNextIndex(step);
      this.$emit(direction, {
        original: this.currentIndex,
        next: nextIndex,
      });
      this.move(step);
      this.auto();
    },
    getNextIndex(step) {
      const slidersLen = this.sliderItems.length;
      if (!this.sliderItems[this.currentIndex]) {
        this.currentIndex = slidersLen - 1;
      }
      return (this.currentIndex + step + slidersLen) % slidersLen;
    },
    canMove() {
      return this.sliderItems.length > 1;
    },
    handleMouseenter() {
      if (this.autoplay && this.stopOnHover) {
        this.isStopped = true;
        if (this.timer) clearInterval(this.timer);
      }
    },
    handleMouseleave() {
      if (this.autoplay && this.stopOnHover) {
        this.isStopped = false;
        this.auto();
      }
    },
    // 动画完成后触发
    animationAfterLeave(){
      this.$emit('animationDone',this.currentIndex)
    }
  },
};
