
import canvaslogo from './logoBase'
import canvasBg from './canvasBg'
export default {
  data(){
    return {
      logo: null,
      initial: false
    }
  },
  mounted(){
  },
  methods: {
    init(){
      if(this.initial) return
      this.initial = true
      this.logo = new canvaslogo(this.$refs.canvasLogo)
      canvasBg.init(this.$refs.canvasLogo)
      // const width = this.$refs.canvasLogo.offsetWidth
      // const height = this.$refs.canvasLogo.offsetHeight
      // console.log(width,height)
      // this.$refs.canvas.height = height ||500
      // this.$refs.canvas.width = width || 1200
      // canvaslogo()
    }
  },
  beforeDestroy(){
    canvasBg?.destroyed()
    console.log(this.logo)
    this.logo?.destroyed()
  }
}
