
export default {
  props: {
    src: {
      type: String
    },
    title: {
      type:String
    },
    descript: {
      type: String
    }
  }
}
