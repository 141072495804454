
import HomeTitile from "@/components/homeTitle/homeTitle.vue";
import Footer from "@/layouts/footer.vue";
import CanvasLogo from "../components/canvasLogo/canvasLogo.vue";
import Swiper from "@/components/swiper/swiper.vue";
import SwiperItem from "@/components/swiper/swiper-item.vue";
import CircleProgress from '@/components/circleProgress/circleProgress.vue'
import Mouse from '@/components/mouse/mouse.vue'
import LoadingIP from '../components/LoadingIP/LoadingIP.vue';
// import Dialog from '@/components/dialog/dialog.vue'
export default {
  name: "index",
  inject: ["showFooter", "switchHeaderStatus"],
  data() {
    return {
      bannerList: [
        {
          id: 0,
          title: "橡塑硅胶精密电子产品<br />一站式研发制造组装",
          indicatorsDescript: "橡塑硅胶精密电子产品一站式研发制造组装",
          src: require("../assets/video/1.mp4"),
          progress: 0,
          descripts: [
            "塑胶注塑，双色，多色，套啤，埋入成型",
            '液态硅胶注塑成型，固态硅胶注塑成型，油压成型',
            '模具设计与制造',
            '喷漆印刷镭雕等表面处理工艺',
            '超声波焊接，自动化整机组装'
          ],
        },
        {
          id: 1,
          title: "医疗用品制造",
          progress: 0,
          indicatorsDescript: "医疗用品制造",
          src: require("../assets/video/2.mp4"),
          descripts: [
            "液态硅胶LSR制品",
            '塑胶，硅胶，五金等包胶， 多料成型 医疗器材部件',
            '医疗级级别模具制造',
            '整机医疗器材组装',
            "医疗企业深度合作",
            "ISO 13485质量管理认证",
          ],
        },
        {
          id: 2,
          title: "精密模具制造",
          progress: 0,
          src: require("../assets/video/3.mp4"),
          indicatorsDescript: "精密模具制造",
          descripts: [
            "模具设计丨模流分析丨模具加工",
          ],
        },
        {
          id: 3,
          title: "工业电子/汽车部件制造",
          progress: 0,
          src: require("../assets/video/4.mp4"),
          indicatorsDescript: "工业电子/汽车部件制造",
          descripts: [
            "氮气辅助成型",
            '多色，包胶注塑成型',
            '防水功能验证与测试',
            '硅胶按键，密封圈，塑胶件',
            '电子组装'
          ],
        },
      ],
      featureList: [
          {
            id: 1,
            hover: true,
            src: require("../assets/img/home/feature_1.png"),
            title: "精密模具设计制造",
            link: "/detail/jm",
            descript:
              "我们有数十位 20 年以上资深模具技术团队，为客户提供精湛的模具加工技术，工匠品质，精致可靠。",
          },
          {
            id: 2,
            hover: false,
            src: require("../assets/img/advnatage/strength_1.jpg"),
            title: "医疗用品设计制造",
            link:"/detail/yl",
            descript:
              "我们拥有丰富的注塑成型及硅橡胶成型技术,能为客户提供复合材料成型或多色多种不同材料的包胶成型,让您的产品更多彩多姿.",
          },
          {
            id: 3,
            hover: false,
            src: require("../assets/img/home/feature_3.png"),
            title: "液态硅胶 LSR 注塑成型",
            link: "/detail/yt",
            descript: "拥有先进的液态硅胶 LSR 成型设备,成型硬度 0-80 度,最高精度可进行微量液态硅胶成型.",
          },
          {
            id: 4,
            hover: false,
            src: require("../assets/img/home/feature_4.png"),
            title: "氮气辅助注塑成型",
            link:"/detail/dq",
            descript:
              "拥有经验丰富氮气辅助成型生产经验,品质优良,交货快速。",
          },
          {
            id: 5,
            hover: false,
            src: require("../assets/img/home/feature_5.png"),
            title: "金属包胶埋入成型",
            link:"/detail/js",
            descript:
              "金属包胶工艺是我们的另一个强项,能为客户提供精密包胶成型代 工,最薄包胶厚度 0.9mm.",
          },
          {
            id: 6,
            hover: false,
            src: require("../assets/img/home/feature_6.png"),
            title: "FPC 触摸屏面板/薄膜开关 OEM 代工",
            link:"/detail/fpc",
            descript:
              "我们拥有先进的冷冻去毛边设备,专业从事橡胶硅胶制品、锌铝镁合金合金压铸件、注塑（塑胶）制品去除毛边批锋作业。",
          },
          {
            id: 7,
            hover: false,
            src: require("../assets/img/home/feature_7.png"),
            title: "精密电子用品组装",
            link:"/detail/jmdzypzz",
            descript: "专业的自动化研发团队为客户进行整机组装规划,提供性价比最适合的组装方案.",
          },
          {
            id: 8,
            hover: false,
            src: require("../assets/img/detail/yd_1.png"),
            title: "液氮冷冻去毛边",
            link:"/detail/yd",
            descript:
              "我们拥有先进的冷冻去毛边设备,专业从事橡胶硅胶制品、锌铝镁合金合金压铸件、注塑（塑胶）制品去除毛边批锋作业。",
          },
          {
            id: 9,
            hover: false,
            src: require("../assets/img/home/feature_9.png"),
            title: "高仿真材料 3D 打印",
            link: '/detail/g3d',
            descript:
              "拥有先进的高仿真材料 3D 打印机，提供客户快速打样，或打印 3D 模仁做快速模具进行首批试产，模拟实际产品的材料硬度及机构。",
          },
      ],
      process: 0,
      acIndex: 0,
      time: null,
      pageIndex: 0,
      prevTime: 0,
      submit: false,
      animationStart: false,
      isStart: false,
      fpcShow: false,
      zuzhuangShow: false,
      jinshuShow: false
    };
  },
  mounted() {
    // 隐藏footer
    this.showFooter(false);
    this.switchHeaderStatus(false);

    // 开启自动切换
    window.addEventListener("wheel", this.mouseWheel);
    this.timeSwitch();

    // this.$nextTick(() => {
    //   setTimeout(()=>{
    //   this.$refs.canvas.init()
    //   }, 0)
    // })
  },
  destroyed() {
    window.removeEventListener("wheel", this.mouseWheel);
  },
  methods: {
    timeSwitch() {
      const reqCallback =  () => {
        const current = this.$refs.videoRef[this.acIndex]
        if ( current && current.currentTime == 0 && current.paused && current.ended) {
          current.play()
        }
        if (current){
          const progress = Math.floor(current.currentTime / current.duration * 100) || 0
          this.bannerList[this.acIndex].progress = progress
          // console.log(this.bannerList[this.acIndex].progress, 'p', progress, Math.floor(current.currentTime / current.duration) * 100)
          if(progress == 100){
          this.bannerList[this.acIndex].progress = 0
            current.currentTime = 0
            this.acIndex++
            this.bannerSwitch()
            if(this.acIndex === 4){
              this.acIndex = 0
            }
          }
          }
        window.cancelAnimationFrame(this.timer)
        this.timer = window.requestAnimationFrame(reqCallback)
        // if(this.bannerList[this.acIndex].progress === 100){
        //   this.bannerList[this.acIndex].progress = 0
        //   this.acIndex++
        //   this.bannerSwitch()
        //   if(this.acIndex === 4){
        //     this.acIndex = 0
        //   }
        // }

        // this.bannerList[this.acIndex].progress++
        // console.log('acIndex',this.acIndex,this.bannerList[this.acIndex].progress)
        // if (this.process === 100) {
        //   this.process = 0;
        //   if (this.acIndex === 3) {
        //     this.acIndex = 0;
        //   } else {
        //     this.acIndex++;
        //   }
        // } else {
        //   this.process += 1;
        // }

        // switch (this.process) {
        //   case 0:
        //     this.acIndex = 0;
        //     this.bannerSwitch();
        //     break;
        //   case 25:
        //     this.acIndex = 1;
        //     this.bannerSwitch();
        //     break;
        //   case 50:
        //     this.acIndex = 2;
        //     this.bannerSwitch();
        //     break;
        //   case 75:
        //     this.acIndex = 3;
        //     this.bannerSwitch();
        //     break;
        //   //   case 100:
        //   //     this.acIndex = 3
        //   //     this.process = 0
        // }
      }
      window.cancelAnimationFrame(this.timer)
      this.timer=window.requestAnimationFrame(reqCallback)
      // this.time = setInterval(, 100);
    },
    bannerSwitch() {
      this.acIndex = this.acIndex % this.bannerList.length
      this.$refs.banner?.move(1);
      // this.$refsvideoRef[0].currentTime = 0
    },
    bannerIndicatorsClick(param) {
      console.log(param, this.acIndex)
      if (param !== this.acIndex) {
        this.bannerList[this.acIndex].progress = 0
        this.acIndex = param;
        console.log(this.$refs)
        this.$refs['videoRef'][0].currentTime = 0

        this.$refs.banner.setIndex(param)
        // this.process = 0;
        // this.bannerSwitch();
      }
    },
    mouseWheel(e) {
      const current = new Date().getTime()
      if (this.animationStart || current - this.prevTime < 1500) {
        return;
      }
      // if (current- this.pageTimeStamp < 1000) {
      //   return
      // }
      this.prevTime = current
      if (e.wheelDelta) {
        //判断浏览器IE，谷歌滑轮事件
        if (e.wheelDelta > 0 && this.pageIndex > 0) {
          //当滑轮向上滚动时
          this.pageIndex--;
          this.pageSwitch(-1);
        }
        if (e.wheelDelta < 0 && this.pageIndex < 4) {
          //当滑轮向下滚动时
          this.pageIndex++;
          this.pageSwitch(1);
        }
      } else if (e.detail) {
        //Firefox滑轮事件
        if (e.detail > 0 && this.pageIndex > 0) {
          //当滑轮向上滚动时
          this.pageIndex--;
          this.pageSwitch(-1);
        }
        if (e.detail < 0 && this.pageIndex < 4) {
          //当滑轮向下滚动时
          this.pageIndex++;
          this.pageSwitch(1);
        }
      }

      if (this.pageIndex === 0) {
        this.switchHeaderStatus(false);
      } else {
        this.switchHeaderStatus(true);
      }
    },
    pageSwitch(param) {
      this.animationStart = true;
      console.log(this.pageIndex);
      if(this.pageIndex == 1) {
        this.$nextTick(() => {
          setTimeout(()=>{
            window.cancelAnimationFrame(this.timer)
          this.$refs.canvas.init()
          }, 0)
        })
      } else if (this.pageIndex === 0) {
        this.timeSwitch()
      }
      this.$refs.pageSwiper.move(param);
    },
    featureEnter(param) {
      this.featureList.forEach(item => item.hover = false);
      // param.hover = true;
    },
    // featureLeave(param) {
    //   param.hover = false;
    // },
    consultClick() {
      this.submit = true;
    },
    cancelSubmitClick() {
      this.submit = false;
    },
    pageSwitchDone() {
      this.animationStart = false;
    },
    featureLeftClick(){
      this.featureSwitch(-1)
      // const itemWidth =this.$refs.featurelistRef.scrollWidth / this.featureList.length
      // this.$refs.featurelistRef.scrollLeft -= itemWidth
      // console.log('test', this.$refs.featurelistRef.scrollLeft= 100)
    },

    featureRightClick(){
      this.featureSwitch(1)
      // const itemWidth =this.$refs.featurelistRef.scrollWidth / this.featureList.length
      // this.$refs.featurelistRef.scrollLeft += itemWidth
    },
    // contentItemEnter(){
    //   this.featureList.forEach(item => item.hover = false)
    // },
    featureSwitch(param){
      if(this.isStart) {
        return
      }
      this.isStart = true
      let speed = 10
      const itemWidth =this.$refs.featurelistRef.scrollWidth / this.featureList.length
      const list = this.$refs.featurelistRef
      const maxLeft = list.scrollWidth - list.offsetWidth
      let target = ~~(param > 0? list.scrollLeft + itemWidth :  list.scrollLeft - itemWidth < 0 ? 0 :  list.scrollLeft - itemWidth)

      if (target < 0 || target > maxLeft) {
        this.isStart =  false
        return
      }
      console.log('test')
      let timer = setInterval(() => {
        if (param > 0) {
          if (Math.abs(list.scrollLeft - target) > speed) {
            list.scrollLeft += speed
          }else{
            list.scrollLeft = target
            this.isStart = false
            clearInterval(timer)
          }
        }else {
          if (Math.abs(list.scrollLeft - target) > speed) {
            list.scrollLeft -= speed
          }else{
            list.scrollLeft = target
            this.isStart = false
            clearInterval(timer)
          }

        }
      }, 1000 / 60)
    },
    featureClick(param){
      const list = ['fpcShow', 'zuzhuangShow', 'jinshuShow']
      if (list.indexOf(param.link) !== -1) {
        this[param.link] = true
      }else{
        // this.$router.push(param.link)
        const {href} = this.$router.resolve(param.link)
      // window.open(location.pathname.replace(/(\/$)/, '') + href, '_blank')

      window.open(href, '_blank')
      }

    }
  },
  components: {
    HomeTitile,
    Footer,
    CanvasLogo,
    SwiperItem,
    CircleProgress,
    Mouse,
    LoadingIP,
    // Dialog,
  },
};
